import React, { useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { AccountContext, ViewContext, Button, Grid, Label, NumberInput, TextInput, Select } from 'components/lib';
import Style from './interruptibles.module.scss';
import moment from 'moment';

import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

export function CreateInterruptible({ updateInterruptible }) {
    const context = useContext(ViewContext);
    const { accountState } = useContext(AccountContext);

    const getOptionData = useCallback((data) => data.map(obj => ({
        label: obj.name,
        value: obj.id
    })), []);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(updateInterruptible?.name);
    const [utility, setUtility] = useState(updateInterruptible?.utility_id);
    const [utilityOptions] = useState(getOptionData(accountState.utilities));
    const [pipeline, setPipeline] = useState(updateInterruptible?.pipeline_id);
    const [pipelineOptions, setPipelineOptions] = useState(null);
    const [startDate, setStartDate] = useState(updateInterruptible?.start_date);
    const [endDate, setEndDate] = useState(updateInterruptible?.end_date);
    const [maxStorage, setMaxStorage] = useState(updateInterruptible?.maximum_storage);
    const [maxDailyVolume, setMaxDailyVolume] = useState(updateInterruptible?.maximum_daily_volume);
    const [currentStorage, setCurrentStorage] = useState(updateInterruptible?.current_storage);

    const [updateWithFlow] = useState(updateInterruptible?.flow_entry_count)

    useEffect(() => {
        const getPipeLines = async () => {
            try {
                const res = await Axios.get('/api/pipeline', {  params: { utility_id: utility } });
          
                if (res.status === 200) {
                    const pipelineRes = res.data.data;

                    if (pipelineRes.length) {
                        setPipelineOptions(getOptionData(pipelineRes));                        
                    }

                }          
              }
              catch (err){
                context.handleError(err);
              }
        }
        if (utility) {
            getPipeLines();
        }
    }, [context, utility, setPipelineOptions, getOptionData]);

    const isValid = () => name && startDate && endDate && maxStorage > 0 && maxDailyVolume > 0;

    const prepareDatePayloadData = (startDate, endDate) => {
        if (!startDate || !endDate) return;
        
        let startDateObj = moment(startDate);
        let endDateObj = moment(endDate);

        startDateObj = startDateObj.startOf('month');
        endDateObj = endDateObj.startOf('month');

        return {
            startDate: startDateObj.toISOString(),
            endDate: endDateObj.toISOString()
        }
    }

    const submit = async () => {
        
        setLoading(true);

        const dateData = prepareDatePayloadData(startDate, endDate);

        if (!dateData) {
            setLoading(false);
            return;
        }

        const payload = { 
            name,
            utility_id: utility,
            pipeline_id: pipeline,
            start_date: dateData.startDate,
            end_date: dateData.endDate,
            maximum_storage: maxStorage,
            maximum_daily_volume: maxDailyVolume,
            current_storage: currentStorage !== undefined ? currentStorage : 0
        };

        if (updateInterruptible) {
            payload['interruptible_id'] = updateInterruptible.id;
        }

        try {
            const res = await Axios[updateInterruptible ? 'patch' : 'post']('/api/interruptible', payload);
          
            if (res.status === 200) {
                setLoading(false);
                    
                // send the user some where else
                context.modal.hide(false, res.data);
            }          
        } catch (err){
            setLoading(false);
            context.handleError(err);
            context.modal.hide(true);
        }
        
    }

    const onDateRangeChange = (dateObjs, dates) => {
        if(dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    }

    const getMinimumDate = () => {
        // moment obj day / month are zero based
        const currentDate = moment();
        const year = currentDate.year();
        const month = currentDate.month();
        const day = currentDate.day();

        let minMonth = (!day) ? month : month + 1;
        minMonth = String(minMonth).length === 1 ? `0${minMonth + 1}` : minMonth + 1;
        const minYear = (month < 0) ? year - 1 : year; 
        const minDay = '01';

        return `${minYear}-${minMonth}-${minDay}`;
    }

    const getDefaultRange = () => [moment.utc(startDate), moment.utc(endDate)];

    const renderForm = () => {
        if (updateWithFlow) {
            return (
                <>
                    <Grid cols="1">
                        <div>
                            <TextInput 
                                label="Name"
                                required
                                name="name"
                                id="name"
                                onChange={(id, value) => setName(value)}
                                value={name}
                            />
                        </div>
                        <div>
                            <NumberInput 
                                label="Current Storage"
                                required
                                isNegative
                                placeholder="0"
                                name="current_storage"
                                id="current_storage"
                                onChange={(id, value) => {
                                    setCurrentStorage(value)
                                }}
                                value={currentStorage}
                            />
                        </div>
                    </Grid>
                    <Grid cols="2">
                        <div>
                        <NumberInput 
                                label="Maximum Storage"
                                required
                                placeholder="0"
                                name="maximum_storage"
                                id="maximum_storage"
                                onChange={(id, value) => setMaxStorage(value)}
                                value={maxStorage}
                            />
                        </div>
                        <div>
                            <NumberInput 
                                label="Maximum Daily Volume"
                                required
                                placeholder="0"
                                name="maximum_daily_volume"
                                id="maximum_daily_volume"
                                onChange={(id, value) => setMaxDailyVolume(value)}
                                value={maxDailyVolume}
                            />
                        </div>
                    </Grid>
                </>
            );
        }

        return (
            <>
            <Grid cols="2">
                <div>
                    <TextInput 
                        label="Name"
                        required
                        name="name"
                        id="name"
                        onChange={(id, value) => setName(value)}
                        value={name}
                    />
                </div>
                <div>
                    <Select
                        customLabel="Utility"
                        required
                        name="utility_id"
                        value={utility}
                        default={utility}
                        onChange={(id, value) => setUtility(value)}
                        options={utilityOptions}
                    >
                    </Select>
                </div>
            </Grid>
            <Grid cols="2">
                {utility &&
                    <div>
                        <Select
                            customLabel="Pipeline"
                            required
                            name="pipeline_id"
                            value={pipeline}
                            default={pipeline}
                            onChange={(id, value) => setPipeline(value)}
                            options={pipelineOptions}
                        >
                        </Select>
                    </div>
                }
                <div>
                    <NumberInput 
                        label="Current Storage"
                        required
                        isNegative
                        placeholder="0"
                        name="current_storage"
                        id="current_storage"
                        onChange={(id, value) => {
                            setCurrentStorage(value)
                        }}
                        value={currentStorage}
                    />
                </div>
            </Grid>
            <Grid cols="2">
                <div>
                    <NumberInput 
                        label="Maximum Storage"
                        required
                        placeholder="0"
                        name="maximum_storage"
                        id="maximum_storage"
                        onChange={(id, value) => setMaxStorage(value)}
                        value={maxStorage}
                    />
                </div>
                <div>
                    <NumberInput 
                        label="Maximum Daily Volume"
                        required
                        placeholder="0"
                        name="maximum_daily_volume"
                        id="maximum_daily_volume"
                        onChange={(id, value) => setMaxDailyVolume(value)}
                        value={maxDailyVolume}
                    />
                </div>
            </Grid>
            <Grid cols="2">
                <div>
                    <Label text="Select Date Range" />
                    <RangePicker 
                        style={{ width: '100%' }}
                        onChange={onDateRangeChange} 
                        picker="month"
                        //disabledDate={d => !d || d.isBefore(getMinimumDate())} 
                        direction="ltr"
                        defaultValue={updateInterruptible ? getDefaultRange() : undefined}
                    />
                </div>
            </Grid>
        </>
        );
    }

    return (
        <div className={Style.createInterruptibleModal}>
            { renderForm() }
            <Grid>
                {isValid() &&
                    <Button
                        loading={ loading }
                        text={updateInterruptible ? "Edit Interruptible" : "Create Interrupble"}
                        action={ submit }
                    />
                }
                <Button outline text='Cancel' action={ () => context.modal.hide(true) } />
            </Grid>
        </div>
    );

}